// import './App.css';
//
import {useEffect, useState} from "react";
import axios from "axios";

function App() {
  const [isloading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const [list, setList] = useState([]);
  const [names, setNames] = useState('');
  const [telegram_id, setTelegram_id] = useState('');
  const [monthCount, setMonthCount] = useState('');
  const [error, setError] = useState('');
  const [userData, setUserData] = useState({
      username: '',
      password: '',
  })
  const changeHandler = event => {
      setUserData({...userData, [event.target.name]: event.target.value})
  }

    const login = e => {
      e.preventDefault();
      console.log(userData);
      axios.post('https://team-backend-new.tds.media/auth/', {...userData}).then(
          res=>{
              localStorage.setItem("token", `${res.data.token}`);
              window.location.reload();
          }
      ).catch(
          err=>{
              console.log(err);
              setError('учетные данные не верны');
              setTimeout(()=>{
                  setError('')
              }, 2000)
          }
      )
    }

    const formlist = e => {
      e.preventDefault()
        if(monthCount === ''){
            setError('введите количетсво месяцев');
            setTimeout(()=>{
                setError('')
            }, 2000)
        }else{
            setIsLoading(true);
            axios.get(`https://team-backend-new.tds.media/telegram/get_paycheck_list/${telegram_id}/${monthCount}/`, {headers:{
                    "Authorization": `Token ${token}`
                }}).then(

                res=>{
                    setIsLoading(false);
                    setList(res.data.links)
                }
            ).catch(
                err=>{
                    console.log(err);
                    setError('не предвиденная ошибка, попробуйте позднее');
                    setTimeout(()=>{
                        setError('')
                    }, 2000)
                }
            )
        }
    }


  useEffect(() => {
      const temp_token = localStorage.getItem("token");
      if (temp_token){
          setToken(temp_token)
          axios.get('https://team-backend-new.tds.media/users/me/', {headers:{
                  "Authorization": `Token ${temp_token}`
              }
          }).then(
              res=>{
                  setNames(res.data.name)
                  setTelegram_id(`${res.data.data[6].value}`)
              }
          ).catch(
              err=>{
                  console.log(err)
              }
          )
      }
  },[])



  return (
    <div className="App">

      <div className="login">

              <h1 className="login__logo" style={{marginBottom:0}}><span className="login__logo__yellow">TDS</span>team</h1>
              <p style={{fontSize:14, margin:0, textAlign:"center"}}>account service beta</p>
              <div className="login__center" style={{marginTop:50, minWidth:300}}>
                  {
                      token === '' ? <>
                        <h2 className="login__center__title">Получение учетный данных</h2>
                          <p>Форма предназначена для <br/> получения расчетных листов от Бухгалтерии</p>
                        <form className="login__center__form" autoComplete="off" onSubmit={login}>
                            {
                                error !== '' ? <p style={{color:"red", margin:0, padding:5}}>{error}</p> : null
                            }
                          <input name={'username'} onChange={changeHandler} placeholder={"Логин от тимса"} style={{fontSize:16, order:"1px solid rgba(255,255,255,0.25)", padding:5, borderRadius:4}}/>
                          <input name={'password'} onChange={changeHandler} placeholder={"Пароль от тимса"} type={'password'} style={{fontSize:16, order:"0.5px solid rgba(255,255,255,0.25)", padding:5, borderRadius:4}}/>
                          <button style={{fontSize:16, padding:5, borderRadius:4}}
                            type={'submit'}
                          >
                            Предоставить доступ
                          </button>
                        </form>
                      </> : <>
                          {
                              isloading ? <>
                                <p>Пожалуйста подождите идет формирование листа</p>
                              </> : <>

                                  <h2 className="login__center__title">Формирование листа</h2>
                                  <p>Система отправит вам файл <br/> который вы сможете скачать здесь</p>
                                  {
                                      error !== '' ? <p style={{color:"red", margin:0, padding:5}}>{error}</p> : null
                                  }
                                  <form className="login__center__form" autoComplete="off" onSubmit={formlist}>
                                      <input type={'number'} name={'month_count'} onChange={(event)=>{setMonthCount(event.target.value)}} placeholder={"впишите количество месяцев"} style={{fontSize:16, order:"1px solid rgba(255,255,255,0.25)", padding:5, borderRadius:4}}/>
                                      <button style={{fontSize:16, padding:5, borderRadius:4}}
                                              type={'submit'}
                                      >
                                          Запросить лист
                                      </button>
                                      {
                                          list.length > 0 ?
                                              list.map((item, index)=>(
                                                  <a key={index} href={`https://team-backend-new.tds.media/${item}`} download>Скачать лист для {names} {index+1}</a>
                                              )) : null

                                      }
                                  </form>
                              </>
                          }
                      </>
                  }
              </div>
      </div>
    </div>
  );
}

export default App;
